<template>
  <div>
    <smooth-picker ref='smoothPickerComponent' :data='smoothPickerParams' :change="smoothPickerChange" />
  </div>
</template>

<script>
import { SmoothPicker } from 'vue-smooth-picker'
import 'vue-smooth-picker/dist/css/style.css'
export default {
  name: 'DateSelect',
  components: { SmoothPicker },
  props: {
    value: {
      type: Date,
      default: null,
      required: false
    },
    max: {
      type: Date,
      default: null,
      required: false
    },
  },
  data() {
    return {
      smoothPickerParams: [
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
        {
          currentIndex: 0,
          flex: 3,
          list: [],
          textAlign: 'center',
          className: 'item-group box-day',
        },
      ]
    }
  },
  computed: {
    selectedDate() {
      return this.smoothPickerParams[0].currentIndex + 1;
    },
    selectedMonth() {
      return this.smoothPickerParams[1].currentIndex + 1;
    },
    selectedYear() {
      return this.smoothPickerParams[2].list[this.smoothPickerParams[2].currentIndex];
    },
    fullDate() {
      const date = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDate)
      return this.checkValidDate(date) ? date : null
    }
  },
  watch: {
    fullDate(value) {
      this.$emit('input', value)
    },
    value() {
      this.update();
    }
  },
  created() {
    this.generateYears();
    this.generateMonths(this.value ? this.value.getFullYear() : this.max ? this.max.getFullYear() : new Date().getFullYear());
    this.generateDays(this.smoothPickerParams[2].list[this.smoothPickerParams[2].currentIndex], this.smoothPickerParams[1].currentIndex + 1);
    this.init();
  },
  methods: {
    init() {
      if (this.checkValidDate(this.value)) {
        this.smoothPickerParams[2].currentIndex = this.smoothPickerParams[2].list.findIndex(yearItem => yearItem === this.value.getFullYear())
        this.smoothPickerParams[1].currentIndex = this.value.getMonth()
        this.smoothPickerParams[0].currentIndex = this.value.getDate() - 1
      }
    },
    update() {
      if (this.checkValidDate(this.value)) {
        this.smoothPickerParams[2].currentIndex = this.smoothPickerParams[2].list.findIndex(yearItem => yearItem === this.value.getFullYear())
        this.smoothPickerParams[1].currentIndex = this.value.getMonth()
        this.smoothPickerParams[0].currentIndex = this.value.getDate() - 1
      } else {
        this.smoothPickerParams[2].currentIndex = 0
        this.smoothPickerParams[1].currentIndex = 0
        this.smoothPickerParams[0].currentIndex = 0
      }
    },
    generateYears() {
      const years = [];
      const maxYear = this.max ? this.max.getFullYear() : new Date().getFullYear();
      for (let i = 1900; i <= maxYear; i++) {
        years.push(i);
      }
      this.smoothPickerParams[2].list = years
    },
    generateMonths(year) {
      const months = [];
      for (let i = 0; i < 12; i++) {
        const date = new Date(year, i, 1);
        if (this.max && date.getFullYear() === this.max.getFullYear() && date.getMonth() > this.max.getMonth()) {
          break;
        }
        months.push(date.toLocaleString('en-us', { month: "long" }));
      }
      this.smoothPickerParams[1].list = months
    },
    generateDays(year, month) {
      const countOfDays = this.getCountDaysInMonth(year, month)

      const days = [];
      for (let i = 1; i <= countOfDays; i++) {
        const date = new Date(year, month - 1, i);
        if (this.max && date > this.max) {
          break;
        }
        days.push(i);
      }
      this.smoothPickerParams[0].list = days
    },
    getCountDaysInMonth(year, month) {
      const date = new Date(year, month, 0);
      return date.getDate();
    },
    smoothPickerChange(setGroupData, getCurrentIndexList) {
      const lastCountDays = (this.max && this.max.getFullYear() === this.selectedYear && this.max.getMonth() + 1 === this.selectedMonth) ?
        this.max.getDate() : this.getCountDaysInMonth(this.selectedYear, this.selectedMonth);
      const lastDate = this.selectedDate;
      const lastMonth = this.selectedMonth;
      this.smoothPickerParams[setGroupData].currentIndex = getCurrentIndexList
      if (setGroupData === 2) {
        this.generateMonths(this.selectedYear)
        if (this.smoothPickerParams[1].list.length < lastMonth) {
          this.smoothPickerParams[1].currentIndex = this.smoothPickerParams[1].list.length - 1
          this.$refs.smoothPickerComponent.currentIndexList[1] = this.$refs.smoothPickerComponent.data[1].list.length - 1;
        }
      }
      if (setGroupData === 1 || setGroupData === 2) {
        this.generateDays(this.selectedYear, this.selectedMonth)
        const newCountDays = (this.max && this.max.getFullYear() === this.selectedYear && this.max.getMonth() + 1 === this.selectedMonth) ?
          this.max.getDate() : this.getCountDaysInMonth(this.selectedYear, this.selectedMonth);
        if (lastCountDays > newCountDays && newCountDays < lastDate) {
          this.smoothPickerParams[0].currentIndex = this.smoothPickerParams[0].list.length - 1
          this.$refs.smoothPickerComponent.currentIndexList[0] = newCountDays - 1
        }
      }
    },
    checkValidDate(date) {
      if (Object.prototype.toString.call(date) === "[object Date]") {
        if (isNaN(date.getTime())) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }
}
</script>
