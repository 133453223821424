<template>
  <view-container v-if='user' name="personal-info">
    <view-header>
      <custom-header class="header__custom">
        <div class="sub-page-settings__title header__title">
          <div>
            Personal info
          </div>
        </div>
        <template #last>
          <router-link-back class="settings__save">Cancel</router-link-back>
        </template>

        <!-- <template #last>
          <button class="btn-icon-t1 settings__save" @click="handleUserUpdate">
            <svg-icon size="size20x20" :name="'icoSave'" />
            Save
          </button>
        </template> -->

        <!-- <template #last>
          <svg-icon size="size20x20" :name="'icoArrowLeftBlue'" />
          Back
        </template> -->
      </custom-header>
    </view-header>

    <view-content>
      <div data-vue-name="PersonalInfo" class="personal-info layout-grid-2row-revers">
        <div class="container-fluid scroll-hide">
          <form class="google-style-input">
            <div class="group">
              <div class="avatar-wrapper">
                <div class="avatar-icon" @click="openFileSelect">
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.55198 1.33333L4.21865 2.66667H1.66665V10.6667H12.3333V2.66667H9.78131L8.44798 1.33333H5.55198ZM4.99998 0H8.99998L10.3333 1.33333H13C13.1768 1.33333 13.3464 1.40357 13.4714 1.5286C13.5964 1.65362 13.6666 1.82319 13.6666 2V11.3333C13.6666 11.5101 13.5964 11.6797 13.4714 11.8047C13.3464 11.9298 13.1768 12 13 12H0.99998C0.823169 12 0.653599 11.9298 0.528575 11.8047C0.403551 11.6797 0.333313 11.5101 0.333313 11.3333V2C0.333313 1.82319 0.403551 1.65362 0.528575 1.5286C0.653599 1.40357 0.823169 1.33333 0.99998 1.33333H3.66665L4.99998 0ZM6.99998 10C6.02752 10 5.09489 9.61369 4.40725 8.92606C3.71962 8.23843 3.33331 7.30579 3.33331 6.33333C3.33331 5.36087 3.71962 4.42824 4.40725 3.74061C5.09489 3.05297 6.02752 2.66667 6.99998 2.66667C7.97244 2.66667 8.90507 3.05297 9.5927 3.74061C10.2803 4.42824 10.6666 5.36087 10.6666 6.33333C10.6666 7.30579 10.2803 8.23843 9.5927 8.92606C8.90507 9.61369 7.97244 10 6.99998 10ZM6.99998 8.66667C7.61882 8.66667 8.21231 8.42083 8.6499 7.98325C9.08748 7.54566 9.33331 6.95217 9.33331 6.33333C9.33331 5.7145 9.08748 5.121 8.6499 4.68342C8.21231 4.24583 7.61882 4 6.99998 4C6.38114 4 5.78765 4.24583 5.35006 4.68342C4.91248 5.121 4.66665 5.7145 4.66665 6.33333C4.66665 6.95217 4.91248 7.54566 5.35006 7.98325C5.78765 8.42083 6.38114 8.66667 6.99998 8.66667Z"
                      fill="#D8D8D8" />
                  </svg>
                  <input class="avatar-upload" type="file" accept="image/*" @change="chooseFile" ref="uploadAvatarInp" />
                </div>
                <a-avatar v-if="photo && previewState" class="avatar-image" :src="previewState" :size="120">
                  <!-- <a-icon slot="icon" type="user" /> -->
                </a-avatar>
                <a-avatar v-else-if="photo && !previewState" class="avatar-image" :src="photo" :size="120">
                  <!-- <a-icon slot="icon" type="user" /> -->
                </a-avatar>
                <a-avatar v-else-if="previewState" class="avatar-image" :src="previewState" :size="120"></a-avatar>
                <a-avatar v-else-if="!photo && !info.name" :src="photo" :size="110">
                  <a-icon slot="icon" type="user" />
                </a-avatar>
                <div class="avatarEmpty" v-else>
                  {{ getFirstLetters() }}
                </div>

              </div>
            </div>
            <div class="group">
              <input type="text" name="full-name" required v-model="info.name" maxlength="30" />
              <label class="required-input">Full name</label>

              <span v-if="errors.name" class="danger google-style-input__description">{{ errors.name[0] }}</span>
            </div>
            <div class="group">
              <div v-if='nicknameAlreadyTaken' class='group__error'>This nickname has already been taken.</div>
              <input type="text" name="nickname" v-model="info.nickname" maxlength="20" />
              <label>Nickname</label>
              <span v-if="errors.nickname" class="danger google-style-input__description">{{ errors.nickname[0] }}</span>
            </div>
            <div class="group country">
              <label class="label--focused required-input">Country</label>
              <a-select v-model="info.country_id">
                <a-select-option v-for="country in countries" :key="country.id">
                  <img :src="country.icon" class="country-ico" height="16px" width="24px">
                  {{ country.name }}
                </a-select-option>
              </a-select>

              <span v-if="errors.country_id" class="danger google-style-input__description">{{ errors.country_id[0]
              }}</span>
            </div>

            <div class="group timezone">
              <label class="required-input">Timezone</label>
              <a-select show-search v-model="info.timezone">
                <a-select-option v-for="timezone in timezones" :key="timezone">
                  {{ timezone }}
                </a-select-option>
              </a-select>

              <span v-if="errors.timezone" class="danger google-style-input__description">
                {{ errors.timezone[0] }}
              </span>
            </div>

            <div class="group">
              <div class="required-input label-title fake-input">Birth Date</div>
              <div v-if='birthday' @click="showModal" class="pseudo-input birthday-field">{{ birthday }}</div>
              <span v-if="errors.birthday" class="danger google-style-input__description">
                {{ errors.birthday[0] }}
              </span>
            </div>
            <div>

              <div v-if="isModalOpen" class="modal">
                <div class="modal-content">
                  <date-select v-model='info.birthday' :max='minBirthDayDate' />
                  <button class="button__save" @click="saveChanges">Apply</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <button class="button__save" @click="handleUserUpdate">Save</button>
    </view-content>
  </view-container>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import DateSelect from '@/components/DateSelect/DateSelect'
import RouterLinkBack from '@/components/RouterButtonBack/RouterLinkBack.vue'

export default {
  name: 'PersonalInfo',
  components: {
    DateSelect,
    RouterLinkBack
  },
  created() {
    const user = this.user

    if (!user) {
      return
    }

    this.info.timezone = this.kyiv(user.timezone)
    if (user.birthday) {
      this.info.birthday = new Date(user.birthday)
    } else {
      let date = new Date()
      date = date.setFullYear(date.getFullYear() - 20)

      this.info.birthday = new Date(date)
    }
    // this.info.birthday = new Date(user.birthday)
    this.info.name = user.name
    this.info.nickname = user.nickname

    const country = user.country

    if (!country) {
      return
    }

    this.info.country_id = country.id
  },
  watch: {
    user: {
      deep: true,
      handler() {
        const user = this.user

        if (!user) {
          return
        }
        this.info.birthday = new Date(user.birthday)
        this.info.timezone = this.kyiv(user.timezone)
        if (user.birthday) {
          this.info.birthday = new Date(user.birthday)
        } else {
          let date = new Date()
          date = date.setFullYear(date.getFullYear() - 20)

          this.info.birthday = new Date(date)
        }

        this.info.name = user.name
        this.info.nickname = user.nickname

        const country = user.country

        if (!country) {
          return
        }

        this.info.country_id = country.id
      }
    }
  },
  mounted() {
    this.info.name = this.user && this.user.name

    this.$store.dispatch('getCountries')
    this.select = this.$store.state.auth.user?.country
    this.$store.dispatch('clearErrors')
  },
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      user: ({ auth }) => auth.user,
      countries: ({ services }) => services.countries,
      invitationId: ({ eventHash }) => eventHash.id
    }),
    timezone() {
      return this.$store.state.auth.user?.timezone
    },
    timezones() {
      return moment.tz.names().map((t) => this.kyiv(t))
    },
    birthday() {
      if (this.info && this.info.birthday) {
        const currentDate = new Date(this.info.birthday)
        return `${currentDate.getDate()} ${currentDate.toLocaleDateString('en-US', { month: 'long' })} ${currentDate.getFullYear()}`
      } else {
        return null
      }
    },
    photo: {
      get() {
        return this.$store.state.auth.user?.photo
      },
      set(value) {
        this.info.photo = value
      },
    },
    country: {
      get() {
        return this.$store.state.auth.user?.country
      },
      set(value) {
        const { id } = this.countries.find(c => c.name === value)
        this.info.country_id = id
      },
    },
    currentDate() {
      const [day, month, year] = this.$refs.smoothPicker.getCurrentIndexList()

      const curDay = this.dataDate[0].list[day]
      const curMonth = this.dataDate[1].list[month]
      const curYear = this.dataDate[2].list[year]

      const res = `${curDay} ${curMonth} ${curYear}`
      return res
    },
  },
  data() {
    const nowYear = new Date().getFullYear()
    const years = []
    for (let i = 1940; i <= nowYear; i++) {
      years.push(i)
    }

    const minBirthDayDate = new Date();
    minBirthDayDate.setFullYear(minBirthDayDate.getFullYear() - 5);

    return {
      nicknameAlreadyTaken: false,
      minBirthDayDate,
      isModalOpen: false,
      info: {
        timezone: null,
        country_id: null,
        birthday: null,
        name: '',
        nickname: '',
        photo: this.photo,
      },
      select: null,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      dataDate: [
        {
          currentIndex: 15,
          flex: 3,
          list: [...Array(31)].map((d, i) => i + 1),
          textAlign: 'center',
          className: 'item-group box-day',
        },
        {
          currentIndex: 4,
          flex: 3,
          list: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          textAlign: 'center',
          className: 'row-group box-months',
        },
        {
          currentIndex: 100,
          flex: 3,
          list: years,
          textAlign: 'center',
          className: 'row-group box-year',
        },
      ],
      datePickerState: null,
      avatarState: null,
      previewState: null
    }
  },
  // computed: {
  //   previewImg() {
  //     if (this.)
  //   }

  // },
  methods: {
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
    },
    kyiv(str) {
      return str && str.includes('Kiev') ? 'Europe/Kyiv' : str
    },
    dataChange(gIndex, iIndex) {
      console.info('list', gIndex, iIndex)
      const ciList = this.$refs.smoothPicker.getCurrentIndexList()
      if (gIndex === 0 || gIndex === 1) {
        // year or month changed
        let currentIndex = iIndex
        let monthCount = 30
        let month = iIndex + 1 // month
        if (gIndex === 0) {
          // year
          month = this.dataDate[1].list[ciList[1]]
        }
        switch (month) {
          case 2: {
            let selectedYear = this.dataDate[0].list[ciList[0]] // month
            if (gIndex === 0) {
              // year
              selectedYear = this.dataDate[0].list[iIndex]
            }
            let isLeapYear = false
            if (this.isLeapYear(selectedYear)) {
              isLeapYear = true
            }
            monthCount = 28
            currentIndex = 14
            if (isLeapYear) {
              monthCount = 29
            }
            break
          }
          case 4:
          case 6:
          case 9:
          case 11:
            monthCount = 30
            break
          default:
            monthCount = 31
        }
        const list = [...Array(monthCount)].map((d, i) => i + 1)
        this.$refs.smoothPicker.setGroupData(0, {
          ...this.dataDate[0],
          ...{ currentIndex, list },
        })
        /* const yearP = this.dataDate[2].list[ciList[0]] */
        /* const monthP = this.dataDate[1].list[ciList[1]] */
        /* const dayP = this.dataDate[0].list[ciList[2]] */
        /* this.info.birthday = `${dayP} ${monthP} ${yearP + 1}` */
        const [
          dayC,
          monthC,
          yearC,
        ] = this.$refs.smoothPicker.getCurrentIndexList()

        const curDay = this.dataDate[0].list[dayC]
        const curMonth = this.dataDate[1].list[monthC]
        const curYear = this.dataDate[2].list[yearC]

        const res = `${curDay} ${curMonth} ${curYear}`
        this.info.birthday = res
      }
    },
    getFlag(flagName) {
      try {
        return require(`../../assets/img/${flagName}`)
      } catch (e) {
        return require('../../assets/img/ico-flag-jp.png')
      }
    },
    handleUserUpdate() {
      this.nicknameAlreadyTaken = false
      this.$store.dispatch('updateUser', this.info).then(() => {
        this.saveAvatar()
        this.invitationId ?
          this.$router.push({ name: 'physiologicalData' })
          : this.goBack()
      }).catch(Error => {
        if (Error?.response?.data?.errors?.nickname && Error.response.data.errors.nickname.includes('The nickname has already been taken.')) {
          this.nicknameAlreadyTaken = true
        }
      })
    },
    goBack() {
      this.$router.push({ name: 'settings' })
    },
    chooseFile(e) {
      const [file] = e.target.files || e.dataTransfer.files

      if (!file) {
        return
      }

      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file['type'])) {
        return;
      }

      this.avatarState = file
      const reader = new FileReader
      reader.onload = e => {
        this.previewState = e.target.result
      }
      reader.readAsDataURL(file)
    },
    saveAvatar() {
      let file = this.avatarState;
      if (!file) {
        return
      }
      let formData = new FormData()
      formData.append('photo', file)

      this.$store.dispatch('updateUser', formData)
    },
    getFirstLetters() {
      if (this.info.name) {
        const fullName = this.info.name.split(' ')
        if (fullName.length === 2) {
          return fullName[0].charAt(0) + fullName[1].charAt(0)
        }
        return fullName[0].charAt(0)
      } else {
        return ''
      }
    },
    openFileSelect() {
      this.$refs['uploadAvatarInp'].click()
    },
    showModal() {
      this.isModalOpen = true;
      this.datePickerState = this.info.birthday;
      window.addEventListener("click", this.closeModalOutside, false);
    },
    saveChanges() {
      this.isModalOpen = false;
      this.datePickerState = null;
      window.removeEventListener("click", this.closeModalOutside);
    },
    closeModal() {
      this.isModalOpen = false;
      window.removeEventListener("click", this.closeModalOutside);
      this.info.birthday = this.datePickerState
    },
    closeModalOutside(event) {
      if (event.target.matches(".modal")) {
        this.closeModal()
      }
    },
  }
}
</script>

<style scoped>
.danger {
  color: red !important;
}

.personal-info-view .view-content {
  padding: 0 16px !important;
}

.personal-info .container-fluid {
  padding: 0;
}

.country-name {
  color: #cdcdcd !important;
  cursor: pointer;
}

.avatar-wrapper {
  position: relative;
  height: 120px;
  width: 120px;
  margin: 50px auto;
  border-radius: 50%;
  box-shadow: 1px 1px 15px -5px black;

}

.avatar-wrapper:hover .profile-pic {
  opacity: 0.5;

}

.avatar-image {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  object-fit: cover;
}

.avatar-upload {
  height: 0;
  width: 0;
  opacity: 0;
  cursor: pointer;
  padding: 0 !important;
}

.settings__save {
  font-size: 16px;
  line-height: 22px;
  color: #4285f4;
}

.header__custom {
  width: 100%;
  justify-content: space-between;
}

.header__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button__save {
  background: #D21341;
  border-radius: 8px;
  padding: 12px 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button__save:hover {
  background: #d213409c;
}

.avatarEmpty {
  text-align: center;
  vertical-align: baseline;
  width: 120px;
  height: 120px;
  background: #D8D8D8;
  color: #2F354D;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 60px;
  border-radius: 50%;
  padding: 30px 0;
}

.avatar-icon {
  z-index: 1;
  background-color: #2F354D;
  padding: 3px 7px;
  padding-bottom: 7px;
  position: absolute;
  top: -3px;
  right: -3px;
  border: 5px solid #141723;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.avatar-icon:hover {
  transform: scale(1.2);
}

.modal {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #2F354D;
  border-radius: 10px;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* .birthday-tip {
  display: none;
} */

.birthday-field:hover {
  cursor: pointer;
}

.pseudo-input {
  background-color: #0b0d13;
  padding: 15px 10px;
  border-radius: 10px;
}
</style>
